import React from "react";
import "./home.css";
import { useNavigate } from "react-router-dom";

// interface HomeProps {
//   onClick: () => void;
// }

const Home: React.FC = () => {
    const navigate = useNavigate();
    return (
        <div className="fullscreen-background">
            <button
                className="centered-button"
                onClick={() => navigate("/game")}
            >
                KYMSPACE
            </button>
        </div>
    );
};

export default Home;
