import { Viewport } from 'pixi-viewport';
import { AnimatedSprite, Application, Assets, Container} from 'pixi.js';
import { InputManager } from '.././input/InputManager';


export class ViewManager{
    private app: Application;
    private worldContainer: Container;
    constructor(app: Application, worldContainer: Container){
        this.app = app;
        this.worldContainer = worldContainer;
    }

    public getSmallScreenMinZoom(): number{
        if ((window.innerWidth <= 450) || (window.innerHeight <= 450)){
            return 0.75;
        }
        return 1;
    }

    async activateViewport(){
        const viewport = new Viewport({
            screenWidth: window.innerWidth,
            screenHeight: window.innerHeight,
            events: this.app.renderer.events // the interaction module is important for wheel to work properly when renderer.view is placed or scaled
        })

        viewport.addChild(this.worldContainer);
        
        this.app.stage.addChild(viewport);

        viewport
        .drag({
            direction: 'all',
        })
        .pinch()
        .wheel()
        // .decelerate() //  something to consider about phone // could do 

        const miniumZoomLimit = this.getSmallScreenMinZoom();
        const maximumZoomLimit = 2;

        viewport.clampZoom({
            // need to do #2
            // when zoomed out, needs to retrace back the way it is zoomed
            minScale: miniumZoomLimit,  // Minimum zoom level
            maxScale: maximumZoomLimit,   // Maximum zoom level
        });

        // console.log(`Viewport moved to: x=${viewport.x}, y=${viewport.y}`);

        function getLog(): void {
            const windowHeightHalf = window.innerHeight/2; 
            const windowWidthHalf = window.innerWidth/2;
            const mapHeightHalf = viewport.height/2;
            const mapWidthHalf = viewport.width/2;
            console.log("------------------------inside drag-------------------------");
            console.log(`Viewport screen position: y=${viewport.center.y}, x=${viewport.center.x}`);
            console.log("viewport.height: "+viewport.height)                                            
            console.log("windowHeightHalf: "+windowHeightHalf)
            console.log("mapHeightHalf: "+mapHeightHalf)
            console.log("viewport.width: "+viewport.width)                                            
            console.log("windowwidthHalf: "+windowWidthHalf)
            console.log("mapwidthHalf: "+mapWidthHalf)
            console.log("------------------------end drag-----------------------");
        }

        const getGap = (direction: string): number => {
            const windowHeightHalf = window.innerHeight/2; 
            const windowWidthHalf = window.innerWidth/2;
            const mapHeightHalf = viewport.height/2;
            const mapWidthHalf = viewport.width/2;
            const center_y_offset = (viewport.center.y - windowHeightHalf) * viewport.scale.y;
            const center_x_offset = (viewport.center.x - windowWidthHalf) * viewport.scale.x;

            const topGap = windowHeightHalf - mapHeightHalf - center_y_offset + (15 *viewport.scale.y);
            const bottomGap = windowHeightHalf - mapHeightHalf + center_y_offset - (5 *viewport.scale.y);
            const leftGap = windowWidthHalf - mapWidthHalf - center_x_offset + (25* viewport.scale.x);
            const rightGap = windowWidthHalf - mapWidthHalf + center_x_offset - (25* viewport.scale.x);

            switch (direction) {
                case 'top':
                    // console.log(`Current Zoom: ${viewport.scale.x}, ${viewport.scale.y}`);
                    // console.log("topGap: "+topGap);
                    return topGap
                case 'bottom':
                    // console.log("bottomGap: "+bottomGap);
                    return bottomGap
                case 'left':
                    // console.log("leftGap: "+leftGap);
                    return leftGap
                case 'right':
                    // console.log("rightGap: "+rightGap);
                    return rightGap
                default:
                    return 0;
            }
        }

        function getRestrictedDirections(): string[] {
            let restrictedDirections: string[] = []; 

            if(getGap('top')>=0){
                restrictedDirections.push('down');
            }
            if(getGap('bottom')>=0){
                restrictedDirections.push('up');
            }
            if(getGap('left')>=0){
                restrictedDirections.push('right');
            }
            if(getGap('right')>=0){
                restrictedDirections.push('left');
            }

            return restrictedDirections;
        }

        let lastX = viewport.x;
        let lastY = viewport.y;

        let isZooming = false;

        viewport.on('wheel', () => {
            isZooming = true; 
            lastX = viewport.x;
            lastY = viewport.y;
        });

        viewport.on('moved', async () => {
            if (isZooming) {
                isZooming = false; 
                return; 
            }

            const restrictedDirections = getRestrictedDirections(); 
        
            if (restrictedDirections.includes('up') && viewport.y < lastY) {
                viewport.y = lastY;
            }
        
            if (restrictedDirections.includes('down') && viewport.y > lastY) {
                viewport.y = lastY;
            }
        
            if (restrictedDirections.includes('left') && viewport.x < lastX) {
                viewport.x = lastX;
            }
        
            if (restrictedDirections.includes('right') && viewport.x > lastX) {
                viewport.x = lastX;
            }
        
            lastX = viewport.x;
            lastY = viewport.y;
        });
    }
}
