import { Assets, Container, Sprite, Text } from "pixi.js";
import { Collision } from "../logic/Collision";

class Map {

    private appContainer: Container;
    private tileCols: number;
    private tileRows: number;
    private offsetHeightFromTop: number;
    private gridArr: Tile[][];
    private tilePaths: string[];
    private buildingSidesTilePath: string;
    private screenMidX: number;
    private screenMidY: number;
    static collisionClass: Collision = Collision.getInstance();

    constructor(appContainer: Container, tileCols: number, tileRows: number,
        offsetHeightFromTop: number, tilePaths: string[], buildingSidesTilePath: string,
        screenMidX: number, screenMidY: number) {
        this.appContainer = appContainer;
        this.tileCols = tileCols;
        this.tileRows = tileRows;
        this.offsetHeightFromTop = offsetHeightFromTop;
        this.gridArr = this.getGridArray();
        this.tilePaths = tilePaths;
        this.buildingSidesTilePath = buildingSidesTilePath;
        this.screenMidX = screenMidX;
        this.screenMidY = screenMidY;
    }

    private getGridArray(): Tile[][] {
        const tilesArr: Tile[][] = [];
        const isBlocked = false;
        let tileCount = 1; //at start
        for (let col = 0; col < this.tileCols; col++) {
            const eachColumn: Tile[] = [];
            for (let row = 0; row < this.tileRows; row++) {
                eachColumn.push(new Tile(row, col, tileCount, isBlocked));
                tileCount++;
            }
            tilesArr.push(eachColumn);
        }
        return tilesArr;
    }

    public async getMapTileGrid() { // this used to be called from main to add to stage from main itself
        const twf = 25;      //width of tile in half    = must be 50/2 //previously tileForReference.width / 2
        const thf = 12.5;     //height of tile in half   = must be 25/2 //previously tileForReference.height / 2;

        for (let col = 0; col < this.gridArr.length; col++) {
            for (let row = 0; row < this.gridArr[col].length; row++) {
                // these calculations are to spread the tiles based on the centre of the screen
                let x = this.screenMidX + (col * twf) - (row) * twf;
                let y = this.screenMidY + (-this.offsetHeightFromTop + col + row) * thf;

                const tilePath = this.tilePaths[Math.floor(Math.random() * this.tilePaths.length)];

                const tile = new Sprite(await Assets.load(tilePath));
                const [scaleFactor, offset_x, offset_y] = await this.getScaleFactor(this.tilePaths[0]);
                tile.scale.set(scaleFactor);
                this.gridArr[col][row].setCoOrdinates(x, y);
                this.gridArr[col][row].setTile(tile, offset_x, offset_y);
            }
        }
        return this.gridArr;
    }

    public async createMapTileGrid(isNumbered: boolean = false){
        const grid = await this.getMapTileGrid();
        for(let col = 0; col < this.tileCols; col++){
            for(let row = 0; row < this.tileRows; row++){
                const tile = grid[col][row]?.getTile()
                if (tile) {
                    this.appContainer.addChild(tile);
                    if(isNumbered){
                        this.appContainer.addChild(this.numberingTiles(tile,grid[col][row].getTileNumber(),col,row));
                    }
                } else {
                    console.error(`Tile at position [${col}, ${row}] is undefined.`);
                }
            }
        }
    }

    public async createTileGrid(colStart: number, rowStart: number, colEnd: number,  rowEnd: number, texturePath: string, texturePaths: string[] = [], travel: boolean = true, zIndex?: number){
            for(let j = colStart; j <= colEnd; j++ ){
                for (let i = rowStart; i <= rowEnd; i ++){
                    const oldPath = texturePath;
                    if(texturePaths.length>0){
                        const newPath = texturePaths[Math.floor(Math.random() * texturePaths.length)];
                        await this.createTile(j,i,newPath, zIndex);
                    }
                    else{
                        await this.createTile(j,i,oldPath, zIndex);
                    }
                    
                }
            }
            if(!travel){
                this.getLineAreaCoordinates(colStart, rowStart, colEnd,  rowEnd);
            }

    }


    public getLineAreaCoordinates(colStart: number, rowStart: number, colEnd: number,  rowEnd: number){
        const [tile1midX, tile1midY] = this.gridArr[colStart][rowStart].getXandY();
        const [tile2midX, tile2midY] = this.gridArr[colEnd][rowEnd].getXandY();
        let horizontal = true;
        if(rowStart===rowEnd){
            Map.blockLine(tile1midX,tile1midY,tile2midX,tile2midY,horizontal);
        }
        else{
            horizontal = false;
            Map.blockLine(tile1midX,tile1midY,tile2midX,tile2midY,horizontal);
        }
    }

    public getTileCoordinates(tileNum: number): [number, number] | null {
        for (let j = 0; j < this.gridArr.length; j++) {
            for (let i = 0; i < this.gridArr[j].length; i++) {
                if (this.gridArr[j][i].getTileNumber() === tileNum) {
                    const x: number = this.gridArr[j][i].getX();
                    const y: number = this.gridArr[j][i].getY();
                    return [x, y];
                }
            }
        }
        return null;
    }

    public getTileCoordinatesByRowCol(tileCol: number, tileRow: number): [number, number] | null {
                    const x: number = this.gridArr[tileCol][tileRow].getX();
                    const y: number = this.gridArr[tileCol][tileRow].getY();
                    return [x, y];
    }

    public numberingTiles(tiles: any, count: number, col: number, row: number): Text {
        const colRow = col.toString() + "," + row.toString();
        // const tileNumber = count.toString();
        const numberText = new Text({
            text: colRow,
            style: {
                fontFamily: 'Arial',
                fontSize: 7,
                fill: 0xff1010,
                align: 'center',
            }
        });

        numberText.anchor.set(0.5); // Center the text
        numberText.position.set(tiles.x, tiles.y);
        numberText.zIndex = 2;

        return numberText;
    }

    public async getScaleFactor(texturePath: string){
        const tileSprite = new Sprite(await Assets.load(texturePath));
        const scaleFactor = 50 / tileSprite.width;
        let offset_x = 0;
        let offset_y = 0;
        if(tileSprite.height * scaleFactor > 25){
            offset_y = (25 - (tileSprite.height * scaleFactor))/2;
        }
        return [scaleFactor, offset_x, offset_y];
    }

    public async createTile(col: number, row: number, texturePath: string, zIndex?: number) {
        //set tile
        const [scaleFactor, offset_x, offset_y] = await this.getScaleFactor(texturePath);
        const tileSprite = new Sprite(await Assets.load(texturePath));
        tileSprite.scale.set(scaleFactor);
        if(zIndex){
            tileSprite.zIndex = zIndex;
        }
        this.gridArr[col][row].setTile(tileSprite, offset_x, offset_y);
        //add tile
        const tile = this.gridArr[col][row].getTile();
        if (tile){
            this.appContainer.addChild(tile);
        }
        else {
            console.log(`Tile at ${col}, ${row} not found`);
        }
    }

    public async buildingSidesTileCreator(col: number, row: number, size: number = 6, sidePath: string= this.buildingSidesTilePath) {
        const startY = col - size;
        const startX = row - size;
        const limitY= startY + size + 1
        const limitX = startX + size + 1
        const limitsY = [startY,startY+1,limitY-1,limitY]
        const limitsX = [startX,startX+1,limitX-1,limitX]
        for (let j = startY; j <= limitY; j++) {
            for (let i = startX; i <= limitX; i++){
                if ( limitsY.includes(j) || limitsX.includes(i)){
                    await this.createTile(j,i,sidePath);
                }
            }
        }
    }


    public static blockArea(tileX: number, tileY: number, size: number = 6){
        this.collisionClass.addToBlockArea(tileX,tileY,size);
    }

    public static blockLine(tile1midX: number, tile1midY: number, tile2midX: number, tile2midY: number, horizontal: boolean){
        this.collisionClass.addToBlockLine(tile1midX, tile1midY, tile2midX, tile2midY, horizontal);
    }
    
    public async buildAtTile(col: number, row: number, texturePath: string, size: number = 6, sidePath?: string, scaleFactor: number = 0.1875) {
        const twf = 25;      //width of tile in half    = must be 50/2 //previously tileForReference.width / 2
        const thf = 12.5;     //height of tile in half   = must be 25/2 //previously tileForReference.height / 2;

        const sprite = Sprite.from(await Assets.load(texturePath));
        // sprite.alpha = 0.45;
        sprite.scale.set(scaleFactor);
        sprite.anchor.set(1);
        sprite.zIndex = 1;

        const coordinates = this.gridArr[col][row].getXandY();
        if (coordinates !== null) {
            const [x, y] = coordinates;
            Map.blockArea(x,y,size);
            sprite.position.set(x + (size * twf), y + thf);
        } else {
            console.log(`Tile at ${col}, ${row} not found`);
        }
        if(sidePath){
            if (sidePath!=="none"){
                await this.buildingSidesTileCreator(col,row,size, sidePath);
            }
        }
        else{
            await this.buildingSidesTileCreator(col,row,size, sidePath);
        }
        return sprite;
    }
}

class Tile {
    private x: number;
    private y: number;
    private row: number;
    private col: number;
    private tileNumber: number;
    private isBlocked: boolean;
    private tile: Sprite | undefined;

    constructor(row: number, col: number, tileNumber: number, isBlocked: boolean = false) {
        this.x = 0;
        this.y = 0;
        this.row = row;
        this.col = col;
        this.tileNumber = tileNumber;
        this.isBlocked = isBlocked;
    }

    public setTile(tile: Sprite, offset_x: number = 0, offset_y: number = 0) {
        this.tile = tile;
        this.tile.x = this.x + offset_x;
        this.tile.y = this.y + offset_y;
        this.tile.anchor.set(0.5);
    }

    public getTile() {
        return this.tile;
    }

    public setCoOrdinates(x: number, y: number) {
        this.x = x;
        this.y = y;
    }

    public isTileBlocked() {
        return this.isBlocked;
    }

    public setBlocked(isBlocked: boolean) {
        this.isBlocked = isBlocked;
    }

    public getTileNumber() {
        return this.tileNumber;
    }

    public getXandY() {
        return [this.x, this.y];
    }

    public getX() {
        return this.x;
    }

    public getY() {
        return this.y;
    }


}

export default Map;
