// need to read this later: https://adamemery.dev/articles/pixi-react
import React, { useRef, useEffect } from "react";
import {
    Application,
    Sprite,
    Assets,
    BlurFilter,
    Container,
    AnimatedSprite,
} from "pixi.js";
import { ViewManager } from "./view/ViewManager";
// import './Game.css';
import World from "./world/World";
import { Collision } from "./logic/Collision";

//todo #1
//todo #2
//todo #3

const Game: React.FC = () => {
    const pixiContainer = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const initializePixi = async () => {
            // Create PixiJS Application
            const app = new Application();
            console.log("Game initiated once");

            // Wait for the Renderer to be available
            await app.init({
                resolution: window.devicePixelRatio || 1,
                autoDensity: true,
                resizeTo: window,
            });

            if (!document.querySelector("#pixi-document")) {
                //app.canvas is the document element inserted into DOM
                document.body.appendChild(app.canvas);
                app.canvas.id = "pixi-document";
            }

            const handleResize = () => {
                // console.log("screen size or orientation changed:")
                //need to do #1
                //handle screen rotations and screen size changes
                //so that for drag components the top/bot/side gaps
                //are measured with the latest values
                app.renderer.resize(window.innerWidth, window.innerHeight);
            };

            window.addEventListener("resize", handleResize);

            // Load the background image
            const backgroundTexture = await Assets.load(
                "/assets/images/covers/gameBackgroundCover.png"
            );
            const background = Sprite.from(backgroundTexture);

            background.width = app.screen.width;
            background.height = app.screen.height;

            const blurFilter = new BlurFilter();
            blurFilter.blur = 10; // Adjust the blur amount as needed
            background.filters = [blurFilter];
            app.stage.addChild(background);

            // Adjust the background size on resize
            app.renderer.on("resize", () => {
                background.width = app.screen.width;
                background.height = app.screen.height;
            });

            const worldContainer = new Container();
            const world = new World(app, worldContainer);
            await world.createWorld();

            const viewManager = new ViewManager(app, worldContainer);
            viewManager.activateViewport();

            //MAKING CHARACTER ANIMATEDSPRITE
            const directions = ["up", "down", "left", "right"];
            const startingDirection = "down";

            const textures: any = {};

            let animatedSprite: AnimatedSprite;




            const speedInY = 1.171875 / 5; //1.171875 // 1.5625
            const speedInX = 2.34375 / 5; //2.34375 // 3.125

            let mapObject = world.getMap();

            let kymX = 0;
            let kymY = 0;
            let z = mapObject.getTileCoordinatesByRowCol(17,38);
            if (z){
                [kymX,kymY] = z;
            }


            let distanceToMakeCharacterCenteredY: number;

            const createAnimatedSprites = () => {
                animatedSprite = new AnimatedSprite(
                    textures[startingDirection]
                );
                animatedSprite.animationSpeed = 0.15;
                animatedSprite.loop = true;
                animatedSprite.x = kymX; //app.screen.width / 2;
                animatedSprite.y = kymY - 25; //app.screen.height / 2;
                animatedSprite.anchor.set(0.5);
                animatedSprite.scale.set(0.04);
                animatedSprite.zIndex = 3;
                distanceToMakeCharacterCenteredY = animatedSprite.height/2.5;
                worldContainer.addChild(animatedSprite);
            };

            const loadTextures = async () => {
                for (const direction of directions) {
                    // TO DO hand movements seem, stiff, need to redraw movement of hands
                    textures[direction] = [
                        await Assets.load(
                            `/assets/images/characters/kym/${direction}/${direction}-standing.png`
                        ),
                        await Assets.load(
                            `/assets/images/characters/kym/${direction}/${direction}-startWalkRightFirst.png`
                        ),
                        await Assets.load(
                            `/assets/images/characters/kym/${direction}/${direction}-midWalkRightFirst.png`
                        ),
                        await Assets.load(
                            `/assets/images/characters/kym/${direction}/${direction}-startWalkLeftFirst.png`
                        ),
                        await Assets.load(
                            `/assets/images/characters/kym/${direction}/${direction}-midWalkLeftFirst.png`
                        ),
                    ];
                }

                createAnimatedSprites();
            };

            const directionsPressed: any = {
                ArrowUp: false,
                ArrowDown: false,
                ArrowLeft: false,
                ArrowRight: false,
            };
            const c = Collision.getInstance();

            
            app.ticker.add(async () => {
                for (const [key, value] of Object.entries(directionsPressed)) {
                    
                    if (key === "ArrowUp" && value === true) {
                        if(c.canMove(animatedSprite.x - speedInX, animatedSprite.y - speedInY + distanceToMakeCharacterCenteredY)){
                            animatedSprite.y -= speedInY;
                            animatedSprite.x -= speedInX;
                        }
                    }
                    if (key === "ArrowDown" && value === true) {
                        if( c.canMove(animatedSprite.x + speedInX, animatedSprite.y + speedInY+ distanceToMakeCharacterCenteredY)){
                        animatedSprite.y += speedInY;
                        animatedSprite.x += speedInX;
                    }
                    }
                    if (key === "ArrowLeft" && value === true) {
                        if(c.canMove(animatedSprite.x - speedInX, animatedSprite.y + speedInY+ distanceToMakeCharacterCenteredY)){
                        animatedSprite.y += speedInY;
                        animatedSprite.x -= speedInX;
                    }
                    }
                    if (key === "ArrowRight" && value === true) {
                        if( c.canMove(animatedSprite.x + speedInX, animatedSprite.y - speedInY+ distanceToMakeCharacterCenteredY)){
                        animatedSprite.y -= speedInY;
                        animatedSprite.x += speedInX;
                        }
                    }
                }
            });

            loadTextures();

            function enableAnimation(direction: string) {
                animatedSprite.textures = textures[direction].slice(1, 5);
                animatedSprite.gotoAndPlay(Math.floor(Math.random() * 4));
            }

            function disableAnimation(direction: string) {
                animatedSprite.textures = [textures[direction][0]];
                animatedSprite.gotoAndStop(0);
            }

            let currentDirectionInMotion = "";
            let lastDirectionWalked: string = "down";

            function updateDirectionAnimation() {
                for (const [key, value] of Object.entries(directionsPressed)) {
                    let direction = key.replace("Arrow", "").toLowerCase();
                    if (
                        value === true &&
                        currentDirectionInMotion !== direction
                    ) {
                        currentDirectionInMotion = direction;
                        enableAnimation(direction);
                    }
                }
                if (
                    Object.values(directionsPressed).every(
                        (value) => value === false
                    )
                ) {
                    currentDirectionInMotion = "";
                    disableAnimation(lastDirectionWalked);
                }
            }

            function setDirection(direction: string, value: boolean) {
                directionsPressed[direction] = value;
            }

            document.addEventListener("keydown", (e) => {
                // console.log(e.key);
                switch (e.key) {
                    case "ArrowUp":
                            setDirection("ArrowUp", true);
                        break;
                    case "ArrowDown":
                        setDirection("ArrowDown", true);
                        break;
                    case "ArrowLeft":
                        setDirection("ArrowLeft", true);
                        break;
                    case "ArrowRight":
                        setDirection("ArrowRight", true);
                        break;
                }
                updateDirectionAnimation();
            });

            document.addEventListener("keyup", (e) => {
                switch (e.key) {
                    case "ArrowUp":
                        lastDirectionWalked = "up";
                        setDirection("ArrowUp", false);
                        break;
                    case "ArrowDown":
                        lastDirectionWalked = "down";
                        setDirection("ArrowDown", false);
                        break;
                    case "ArrowLeft":
                        lastDirectionWalked = "left";
                        setDirection("ArrowLeft", false);
                        break;
                    case "ArrowRight":
                        lastDirectionWalked = "right";
                        setDirection("ArrowRight", false);
                        break;
                }
                updateDirectionAnimation();
            });

            /**********ENDS HERE*******/

            window.addEventListener("resize", handleResize);
            window.addEventListener("orientationchange", handleResize);

            return () => {
                window.removeEventListener("resize", handleResize);
                app.destroy(true, true); // Destroy the PIXI application to free resources
            };
        };

        initializePixi();
    }, []);

    return <div ref={pixiContainer}></div>;
};

export default Game;

// const inputManager = new InputManager(sprite, 25, 12.5);
// this.app.stage.interactive = true;
// this.app.stage.on('pointerdown', (event) => inputManager.mousePressed(event));
// document.addEventListener('keydown', (event) => inputManager.keyPressed(event));

// app.ticker.add(() => {
//     if (movement.up) {
//         animatedSprite.y -= speedInY;
//         animatedSprite.x -= speedInX;
//     }
//     if (movement.down) {
//         animatedSprite.y += speedInY;
//         animatedSprite.x += speedInX;
//     }
//     if (movement.left) {
//         animatedSprite.y += speedInY;
//         animatedSprite.x -= speedInX;
//     }
//     if (movement.right) {
//         animatedSprite.y -= speedInY;
//         animatedSprite.x += speedInX;
//     }

//     // Stop the animation when no keys are pressed
//     if (!movement.up && !movement.down && !movement.left && !movement.right) {
//         simulateMovement(currentDirection, false);
//     }
// });

// loadTextures();

// function setDirection(direction: string, value: boolean){
//     directionsPressed[direction] = value;
// }

// document.addEventListener('keydown', (e) => {
//     // console.log(e.key);
//     switch (e.key) {
//         case 'ArrowUp':
//             addDirection('ArrowUp');
//             console.log("up")
//             simulateMovement('up', true);
//             break;
//         case 'ArrowDown':
//             console.log("down")
//             simulateMovement('down', true);
//             break;
//         case 'ArrowLeft':
//             console.log("left")
//             simulateMovement('left', true);
//             break;
//         case 'ArrowRight':
//             console.log("right")
//             simulateMovement('right', true);
//             break;
//     }
// });

// document.addEventListener('keyup', (e) => {
//     switch (e.key) {
//         case 'ArrowUp':
//             console.log(e.key + "key up");
//             movement.up = false;
//             break;
//         case 'ArrowDown':
//             console.log(e.key + "key up");
//             movement.down = false;
//             break;
//         case 'ArrowLeft':
//             console.log(e.key + "key up");
//             movement.left = false;
//             break;
//         case 'ArrowRight':
//             console.log(e.key + "key up");
//             movement.right = false;
//             break;
//     }
//     simulateMovement(currentDirection, false);
// });
