interface Coordinates{
    x: number;
    y: number;
}

type CoordinatesList = Coordinates[];


export class Collision {
    private static instance: Collision| null = null;
    private blockedAreaVerticesList: CoordinatesList[] = [];

    // Coordinates of the rhombus vertices
    private constructor() {
    }

    public static getInstance(): Collision{
        if(Collision.instance===null){
            Collision.instance = new Collision();
        }
        return Collision.instance;
    }
    
//self reminder keep checking if x and y are not interchanged at any arguments while copy pasting
    public getBoxCrossProducts(sprite_pos_x: number, sprite_pos_y: number, blockVertices: any){

        const [A,B,C,D] = blockVertices;
        function crossProduct(x1: number, y1: number, x2: number, y2: number) {
            return x1 * y2 - y1 * x2;
        }
    // console.log(A);
    // Vectors from point to each vertex
        const AB = crossProduct(
            B.x - A.x,
            B.y - A.y,
            sprite_pos_x - A.x,
            sprite_pos_y - A.y
        );
        const BC = crossProduct(
            C.x - B.x,
            C.y - B.y,
            sprite_pos_x - B.x,
            sprite_pos_y - B.y
        );
        const CD = crossProduct(
            D.x - C.x,
            D.y - C.y,
            sprite_pos_x - C.x,
            sprite_pos_y - C.y
        );
        const DA = crossProduct(
            A.x - D.x,
            A.y - D.y,
            sprite_pos_x - D.x,
            sprite_pos_y - D.y
        );

        return [AB,BC,CD,DA];
    }

    public canMove(sprite_pos_x: number, sprite_pos_y: number){
        for(let i = 0 ; i < this.blockedAreaVerticesList.length;i++){
            const [AB,BC,CD,DA] = this.getBoxCrossProducts(sprite_pos_x,sprite_pos_y,this.blockedAreaVerticesList[i]);
            const isInside = AB > 0 && BC > 0 && CD > 0 && DA > 0;
            const isOutside = AB < 0 && BC < 0 && CD < 0 && DA < 0;
            if((isInside || isOutside)===true){
                return false
            }
        }
        return true;

    }

    public addToBlockArea(midX: number, midY: number, size: number = 6): void {
        const thf = 12.5;
        const th = 25;
        const tw = 50;
        const sizeHalf = size / 2;
        const verticalDistance = sizeHalf * tw; // distance in x from mid of the block to left/right
        const horizontalDistance = sizeHalf*th - thf; // distance in y from midpoint of bot-tile to middle point of centre tiles

        const A = { x: midX, y: midY - ((size - 1) * th + thf) };
        const B = { x: midX - verticalDistance, y: midY - horizontalDistance };
        const C = { x: midX, y: midY + thf };
        const D = { x: midX + verticalDistance, y: midY - horizontalDistance  };
        this.blockedAreaVerticesList.push([A,B,C,D]);


        // //TEST
        // const graphics1 = new Graphics();
        // graphics1.fill(0xff0000);

        // graphics1.circle(A.x, A.y, 5 );
        // graphics1.endFill();
        // this.worldContainer.addChild(graphics1);

        // const graphics2 = new Graphics();
        // graphics2.fill(0xff0000);
        // graphics2.circle(B.x, B.y, 5);
        // graphics2.endFill();
        // this.worldContainer.addChild(graphics2);

        // const graphics3 = new Graphics();
        // graphics3.fill(0xff0000);
        // graphics3.circle(C.x, C.y, 5);
        // graphics3.endFill();
        // this.worldContainer.addChild(graphics3);

        // const graphics4 = new Graphics();
        // graphics4.fill(0xff0000);
        // graphics4.circle(D.x, D.y, 5);
        // graphics4.endFill();
        // this.worldContainer.addChild(graphics4);
        // //END TEST
    }

    public addToBlockLine(tile1midX: number, tile1midY: number, tile2midX: number, tile2midY: number, horizontal: boolean): void {
        const thf = 12.5;
        const twf = 25;
        if(horizontal){
            const A = { x: tile1midX, y: tile1midY - thf };
            const B = { x: tile2midX - twf, y: tile2midY };
            const C = { x: tile2midX, y: tile2midY + thf  };
            const D = { x: tile1midX + twf, y: tile1midY};
            this.blockedAreaVerticesList.push([A,B,C,D]);
        }
        else{
            const A = { x: tile1midX, y: tile1midY - thf };
            const B = { x: tile1midX - twf, y: tile1midY };
            const C = { x: tile2midX, y: tile2midY + thf };
            const D = { x: tile2midX + twf, y: tile2midY };
            this.blockedAreaVerticesList.push([A,B,C,D]);
        }
    }
}
