import { Application, Container } from "pixi.js";
import Map from "./Map"


// Map dimensions
const tilesNumberWidth = 47;
const tilesNumberLength = 45;

//Vertical distance between top screen border and map
const screenMidheightHalf = 40;

// Road Tiles
// const parkSideTilePath = '/assets/images/tiles/offwhitetile50.png';
const buildingSidesTilePath = '/assets/images/tiles/border/buildingBorder/offWhite/offWhite.png';
// const roadTilePath = '/assets/images/tiles/creamtile50.png';
const roadTilePaths = ['/assets/images/tiles/roads/sett/type1a.png','/assets/images/tiles/roads/sett/type1b.png',
    '/assets/images/tiles/roads/sett/type1c.png','/assets/images/tiles/roads/sett/type3a.png',
    '/assets/images/tiles/roads/sett/type1d.png','/assets/images/tiles/roads/sett/type3b.png',
    '/assets/images/tiles/roads/sett/type3c.png','/assets/images/tiles/roads/sett/type3d.png'];
    // '/assets/images/tiles/roads/sett/type2a.png','/assets/images/tiles/roads/sett/type4a.png'];
const waterGreenLightTilePaths = ['/assets/images/tiles/water/green/waterGreen1.png',
    '/assets/images/tiles/water/green/waterGreen2.png',
    '/assets/images/tiles/water/green/waterGreen3.png'];
const borderTilePath = '/assets/images/tiles/border/waterBorder/borderWood.png';


//Building images
const bookshopPath      = '/assets/images/buildings/bookshop/bookshop.png';
const restaurantPath    = '/assets/images/buildings/restaurant/restaurant.png';
const conbini1Path      = '/assets/images/buildings/conbini1/conbini1.png';
const apartment1Path    = '/assets/images/buildings/placeholder building/1/apartment1.png';
const apartment2Path    = '/assets/images/buildings/placeholder building/2/apartment2.png';
const apartment3Path    = '/assets/images/buildings/placeholder building/3/apartment3.png';
const apartment4Path    = '/assets/images/buildings/placeholder building/4/apartment4.png';
const flowershopPath    = '/assets/images/buildings/flowershop/flowershop.png';
const cafePath          = '/assets/images/buildings/cafe/cafe.png';
const officePath        = '/assets/images/buildings/office/office.png';
const clothstorePath    = '/assets/images/buildings/clothstore/clothstore.png';
const sushistorePath    = '/assets/images/buildings/sushistore/sushistore.png';
const fishingboatPath   = '/assets/images/buildings/fishingboat/fishingboat.png';
const parkPath          = '/assets/images/buildings/park/bigpark.png';

//Water Border Post



class World{
    private appContainer: Container;
    private map: Map
    constructor(app: Application, appContainer: Container) {
        this.appContainer = appContainer;
        this.map = new Map(appContainer, tilesNumberWidth, tilesNumberLength,
            screenMidheightHalf, waterGreenLightTilePaths, buildingSidesTilePath,
            app.renderer.width / 2, app.renderer.height / 2);
    }

    // public async createWaves(){
    //     await this.map.createTileGrid(0,0,46,44,waterGreenLightTilePaths[0],waterGreenLightTilePaths);
    //     await this.createRoads();
    //     await this.createWaterBorders();
    // }

    public async createWorld(){
        await this.map.createMapTileGrid();
        await this.createBuildings();
        await this.createRoads();
        await this.createWaterBorders();
    }

    public getMap(){
        return this.map;
    }

    public async createBuildings(){
        //Building Locations
        const apartment3Sprite = await this.map.buildAtTile(8, 8, apartment3Path);
        const conbini1Sprite = await this.map.buildAtTile(8, 15, conbini1Path);
        const apartment4Sprite = await this.map.buildAtTile(8, 22, apartment4Path);
        const flowershopSprite = await this.map.buildAtTile(8, 29, flowershopPath);
        const cafeSprite = await this.map.buildAtTile(8, 37, cafePath);
        const apartment1sprite = await this.map.buildAtTile(19, 8, apartment1Path);
        const bookshopSprite = await this.map.buildAtTile(16, 37, bookshopPath);
        const restaurantSprite = await this.map.buildAtTile(26, 8, restaurantPath);
        const parkSprite = await this.map.buildAtTile(26, 26, parkPath, 13, buildingSidesTilePath, 0.27083);
        const apartment2sprite = await this.map.buildAtTile(27, 37, apartment2Path);
        const officeSprite = await this.map.buildAtTile(33, 8, officePath);
        const clothstoreSprite = await this.map.buildAtTile(40, 8, clothstorePath);
        const sushistoreSprite = await this.map.buildAtTile(37, 19, sushistorePath);
        const fishingboatSprite = await this.map.buildAtTile(37, 27, fishingboatPath, 6, "none");

        this.appContainer.addChild(await apartment3Sprite);
        this.appContainer.addChild(await conbini1Sprite);
        this.appContainer.addChild(await apartment4Sprite);
        this.appContainer.addChild(await flowershopSprite);
        this.appContainer.addChild(await cafeSprite);
        this.appContainer.addChild(await apartment1sprite);
        this.appContainer.addChild(await bookshopSprite);
        this.appContainer.addChild(await restaurantSprite);
        this.appContainer.addChild(await parkSprite); 
        this.appContainer.addChild(await apartment2sprite);
        this.appContainer.addChild(await officeSprite);
        this.appContainer.addChild(await clothstoreSprite);
        this.appContainer.addChild(await sushistoreSprite);
        this.appContainer.addChild(await fishingboatSprite);
    }
    public async createRoads(){
        //roads
        //top left
        await this.map.createTileGrid(10, 10, 45, 12, roadTilePaths[0], roadTilePaths);
        //top
        await this.map.createTileGrid(10, 2, 12, 27, roadTilePaths[0], roadTilePaths);
        //right
        await this.map.createTileGrid(28, 13, 30, 30, roadTilePaths[0], roadTilePaths);
        //center bottom
        await this.map.createTileGrid(10, 28, 27, 30, roadTilePaths[0], roadTilePaths);
        //bottom mid
        await this.map.createTileGrid(18, 31, 20, 41, roadTilePaths[0], roadTilePaths);
        await this.map.createTileGrid(2, 39, 20, 41, roadTilePaths[0], roadTilePaths);
    }
    public async createWaterBorders(){
        // border
        //top left
        await this.map.createTileGrid(2, 1, 41, 1, borderTilePath , [], false);

        //top right
        await this.map.createTileGrid(42, 1, 42, 9, borderTilePath , [], false);
        await this.map.createTileGrid(43, 9, 45, 9, borderTilePath , [], false);
        await this.map.createTileGrid(40, 13, 45, 13, borderTilePath , [], false);
        await this.map.createTileGrid(46, 9, 46, 13, borderTilePath , [], false);    
        
        //mid right
        await this.map.createTileGrid(36, 21, 36, 23, borderTilePath , [], false);
        await this.map.createTileGrid(37, 21, 38, 21, borderTilePath , [], false);
        await this.map.createTileGrid(39, 13, 39, 21, borderTilePath , [], false);

        //bottom left
        await this.map.createTileGrid(1, 1, 1, 42, borderTilePath , [], false);
        await this.map.createTileGrid(2, 42, 20, 42, borderTilePath , [], false);

        //bottom mid
        await this.map.createTileGrid(21, 39, 21, 42, borderTilePath , [], false);
        await this.map.createTileGrid(22, 39, 28, 39, borderTilePath , [], false);
        await this.map.createTileGrid(29, 31, 29, 39, borderTilePath , [], false);
        await this.map.createTileGrid(30, 31, 30, 31, borderTilePath , [], false);
        await this.map.createTileGrid(31, 26, 31, 31, borderTilePath , [], false);
    }
}

export default World;